import React from "react";

const Address = () => {
  return (
    <>
      <p className="open-sans-font custom-span-contact position-relative">
        <i className="fa fa-map position-absolute"></i>
        <span className="d-block">Address</span>3442 Stoneway Ct, Champaign ,
        IL 61822.
      </p>
      {/* End .custom-span-contact */}

      <p className="open-sans-font custom-span-contact position-relative">
        <i className="fa fa-envelope-open position-absolute"></i>
        <span className="d-block">mail me</span>{" "}
        <a href="mailto:tofi@archisacademy.com">tofi@archisacademy.com</a>
      </p>
      {/* End .custom-span-contact */}

      <p className="open-sans-font custom-span-contact position-relative">
        <i className="fa fa-phone-square position-absolute"></i>
        <span className="d-block">call me</span>{" "}
        <a href="Tel: +1 240 479 9607">+1 240 479 9607</a>
      </p>
      <p className="open-sans-font custom-span-contact position-relative">
        <i className="fa fa-phone-square position-absolute"></i>
        <span className="d-block">whatsapp me</span>{" "}
        <a href="Tel: +90 538 062 1430">+90 538 062 1430</a>
      </p>
      {/* End .custom-span-contact */}
    </>
  );
};

export default Address;
