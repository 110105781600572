import React from "react";

const educationContent = [
  {
    year: "2022",
    degree: "Tech Up +",
    institute: "Arinkom TTO, Anadolu University",
    details: `  One among 10 ENTREPRENEUR whom got selected for scaling and expanding the business model.`,
  },
  {
    year: "2021",
    degree: "Pre Seed Accelerator",
    institute: "New Chip Accelerator",
    details: `  US based remote Accelerator program to mold and gain our buiness expansion in US markets.`,
  },
  {
    year: "2021",
    degree: "Seed Up Accelerator",
    institute: "Arinkom TTO, Anadolu University",
    details: `Accelerator program to help our business plans and educating. Provided training from a lot mentors.`,
  },
  {
    year: "2009",
    degree: "BACHELOR DEGREE ",
    institute: "M.G. University",
    details: `Gratuated as Electronics and Instrumentation Engineering `,
  },
];

const Education = () => {
  return (
    <ul>
      {educationContent.map((val, i) => (
        <li key={i}>
          <div className="icon">
            <i className="fa fa-briefcase"></i>
          </div>
          <span className="time open-sans-font text-uppercase">{val.year}</span>
          <h5 className="poppins-font text-uppercase">
            {val.degree}
            <span className="place open-sans-font">{val.institute}</span>
          </h5>
          <p className="open-sans-font">{val.details}</p>
        </li>
      ))}
    </ul>
  );
};

export default Education;
