import React from "react";

const experienceContent = [
  {
      year: "   2019 - Present",
      position: " Founder & CEO",
      compnayName: "Archi's Academy",
      details: `We make you skillable, hirable, and employable in software development field! Take a look at our short introduction to learn more about our vision & mission.`,
},
  {
      year: "   2021 - Present",
      position: " Product Owner and Architect",
      compnayName: "Lokum.tech",
      details: `Learning management system build using Web 3.0 technology and gamified to make
trainees engaged`,
},
  {
      year: "   2018 - 2022",
      position: " Wolfram|Alpha - SDM",
      compnayName: "Wolfram Research Inc",
      details: `Managed 12 member technical team for Wolfram Alpha project of Web R&D department`,
},
  {
      year: "   2017 - 2022",
      position: " Wolfram Community - SDM",
      compnayName: "Wolfram Research Inc",
      details: `Managed Wolfram Community development team which consists of 3 team members`,
},
  {
      year: "   2014 - 2022",
      position: " Software Development Manager",
      compnayName: "Wolfram Research Inc",
      details: `Managing 10 member team for development efforts of Wolfram Cloud
and Wolfram Application Server`,
  },
  {
    year: "   2013 - 2014",
    position: " Software Development Lead , Consultant",
    compnayName: "Humana Inc",
    details: `  Leading the effort for Devops team to have automated
CICD`,
  },
  {
    year: "2012 - 2013",
    position: " Software Development Lead , Consultant",
    compnayName: "Statefarm Insurance",
    details: `Development and Module lead for Statefarm Auto Insurance`,
  },
  {
    year: "2009 - 2012",
    position: "Software Developer",
    compnayName: "Tata consultancy services",
    details: `Integral team member of product development for forecasting sales and
revenue in Insurance domain`,
  },
];

const Experience = () => {
  return (
    <ul>
      {experienceContent.map((val, i) => (
        <li key={i}>
          <div className="icon">
            <i className="fa fa-briefcase"></i>
          </div>
          <span className="time open-sans-font text-uppercase">{val.year}</span>
          <h5 className="poppins-font text-uppercase">
            {val.position}
            <span className="place open-sans-font">{val.compnayName}</span>
          </h5>
          <p className="open-sans-font">{val.details}</p>
        </li>
      ))}
    </ul>
  );
};

export default Experience;
